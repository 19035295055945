<template>
    <div class="footer flex fCol acen">
        <div>版权所有：深圳市福田区慈善会 备案序号：粤ICP备17019828号</div>
        <div>地址：深圳市福田区新洲南路石厦四街233号福保街道办410</div>
        <!-- <div>技术支持: </div> -->
    </div>
</template>
<script>
export default {
    name: 'footer',
    data() {
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.footer {
    padding: 48px;
    background: #3D3D3D;

    div {
        font-size: 12px;
        color: #EEEEEE;
        line-height: 24px;
        text-align: center;
    }
}

@media screen and (max-width: 750px) {
    .footer {
        padding: 20px;

    }
}
</style>