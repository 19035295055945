<template>
    <div class="side">
        <div class="project">
            <div class="title">慈善项目</div>
            <vue-seamless-scroll :data="projectList" v-loading="loading" class="list" :class-option="{ step: 0.5 }">
                <router-link :to="{ name: 'detail', query: { id: item.id } }" class="item flex acen"
                    v-for="item in projectList" :key="item.id">
                    <div class="img-m flex jcen">
                        <img :src="imgPath + item.img" alt="">
                    </div>
                    <div class="content line3">{{ item.title }}</div>
                </router-link>
            </vue-seamless-scroll>
        </div>
        <div class="jzmenu">
            <div class="title">我要捐赠</div>
            <div class="list">
                <template v-for="item in infoSideList">
                    <router-link :to="{ name: 'donationForm' }" v-if="item.id == 41" class="item flex acen jcen"
                        :key="item.id">
                        {{ item.name }}
                    </router-link>
                    <router-link :to="{ name: 'expendForm' }" v-else-if="item.id == 43" class="item flex acen jcen"
                        :key="item.id">
                        {{ item.name }}
                    </router-link>
                    <router-link :to="{ name: 'list', query: { id: item.id } }" v-else class="item flex acen jcen"
                        :key="item.id">
                        {{ item.name }}
                    </router-link>
                </template>

            </div>
        </div>
        <div class="ewm">
            <img src="../assets/ewm.png" alt="">
            <div>欢迎关注福田区慈善会</div>
        </div>
    </div>
</template>

<script>
import { getArticlePage } from '@/api/index'
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'side',
    computed: {
        ...mapGetters(['infoSideList']),
    },
    data() {
        return {
            loading: false,
            imgPath: process.env.VUE_APP_IMAGE_URL,
            projectList: []
        }
    },
    created() {
        this.getArticlePage()
    },
    methods: {
        getArticlePage() {
            this.loading = true
            getArticlePage({ cateTId: 18, page: 1, pageSize: 20 }).then(res => {
                this.projectList = res.data.content
                this.loading = false
            }).catch(res => {
                this.loading = false
            })
        },

    }
}
</script>

<style lang="less" scoped>
.side {
    .project {
        margin-bottom: 15px;

        .title {
            height: 40px;
            background: #fc1527;
            color: #fff;
            font-size: 16px;
            text-align: center;
            line-height: 40px;
            border-radius: 4px;
            margin-bottom: 15px;
        }

        .list {
            box-shadow: 0 0 4px 4px #f5f5f5;
            padding: 5px;
            overflow: hidden;
            height: 50vh;

            .item {
                margin-bottom: 10px;

                .img-m {
                    width: 40%;
                    height: 60px;
                    overflow: hidden;
                    border: 1px solid #ddd;
                    flex-shrink: 0;
                    margin-right: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }

                .content {
                    line-height: 20px;
                    color: #3a3a3a;
                    font-size: 12px;
                }
            }
        }
    }

    .jzmenu {
        .title {
            height: 40px;
            background: #fc1527;
            color: #fff;
            font-size: 16px;
            text-align: center;
            line-height: 40px;
            border-radius: 4px;
            margin-bottom: 15px;
        }

        .list {
            .item {
                width: 90%;
                height: 44px;
                color: #fc1527;
                font-size: 14px;
                position: relative;
                border-bottom: 1px solid #f3f3f3;
                margin: 0 auto;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background: #fc1527;

                }

                &::before {
                    width: 6px;
                    height: 6px;
                    content: " ";
                    background: #fc1527;
                    color: #fc1527;
                    position: absolute;
                    top: 19px;
                    left: 16px;
                }
            }
        }
    }

    .ewm {
        margin: 15px;
        border: 1px solid #f5f5f5;

        img {
            width: 100%;
        }

        div {
            color: #3a3a3a;
            font-size: 14px;
            padding-bottom: 10px;
            text-align: center
        }
    }
}
</style>