<template>
    <div class="header">
        <div class="w maAuto flex acen jbet">
            <img @click="goHome" alt="logo" class="logo" src="../assets/logob.png">
            <div class="mymenu flex fCol aend">
                <div class="inputBox flex acen jcen">
                    <el-input placeholder="请输入" suffix-icon="el-icon-search" v-model="keyword" @change="search">
                    </el-input>
                    <div class="openSide hidden-sm-and-up">
                        <i class="el-icon-s-unfold" v-if="!openSide" @click="openSide = true"></i>
                        <i class="el-icon-s-fold" v-else @click="openSide = false"></i>
                    </div>
                </div>
                <el-menu :default-active="activeIndex" class="hidden-xs-only" active-text-color="#FC1527" mode="horizontal"
                    @select="handleSelect">
                    <el-menu-item index="0">首页</el-menu-item>
                    <template v-for="item in allMenuList">
                        <el-menu-item v-if="item.childrenList.length <= 0 && item.status == 1 && item.headerM == 1"
                            :index="item.id + ''" :key="item.id">{{ item.name
                            }}</el-menu-item>
                        <el-submenu :index="item.id + ''" v-else-if="item.status == 1 && item.headerM == 1" :key="item.id"
                            popper-class="mypopper">
                            <template slot="title">
                                <span>{{ item.name }}</span>
                            </template>
                            <el-menu-item :index="son.id + ''" v-for="son in item.childrenList" :key="son.id"
                                v-if="son.status == 1 && son.headerM == 1">{{ son.name
                                }}</el-menu-item>
                        </el-submenu>
                    </template>

                </el-menu>
            </div>
        </div>
        <div class="sideMenu hidden-sm-and-up" :class="{ active: openSide }">
            <el-menu :default-active="activeIndex" background-color="#545c64" text-color="#fff" active-text-color="#FC1527"
                @select="handleSelect">
                <el-menu-item index="0">首页</el-menu-item>
                <template v-for="item in allMenuList">
                    <el-menu-item v-if="item.childrenList.length <= 0 && item.status == 1" :index="item.id + ''"
                        :key="item.id">{{ item.name
                        }}</el-menu-item>
                    <el-submenu :index="item.id + ''" v-else-if="item.status == 1" :key="item.id">
                        <template slot="title">
                            <span>{{ item.name }}</span>
                        </template>
                        <el-menu-item :index="son.id + ''" v-for="son in item.childrenList" :key="son.id"
                            v-if="son.status == 1">{{ son.name
                            }}</el-menu-item>
                    </el-submenu>
                </template>
            </el-menu>
        </div>
    </div>
</template>
<script>
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'myheader',
    computed: {
        ...mapGetters(['activeIndex', 'allMenuList']),
    },
    data() {
        return {
            keyword: '',
            openSide: false,
        }
    },
    created() {

    },
    methods: {
        search() {
            if (this.keyword) {
                this.$router.push({ name: 'list', query: { keyword: this.keyword } })
                this.$store.commit('SET_MENUINDEX', '')
            }
        },
        goHome() {
            if (this.$route.name == 'home') return
            this.$router.push({ name: 'home' })
            this.$store.commit('SET_MENUINDEX', '0')
        },
        handleSelect(e) {
            if (this.$route.query.id == e) return;
            this.$store.commit('SET_MENUINDEX', e);
            
            if (e == 41 || e == 99) {
                return this.navigateTo('donationForm');
            } else if (e == 43) {
                return this.navigateTo('expendForm');
            } else if (e == 0) {
                return this.navigateTo('home');
            } else if (e == 70 || e == 45) {
                return this.navigateTo('projectlist', { type: e == 70 ? 1 : 2 });
            } else if (e == 100) {
                return this.navigateTo('message');
            }
            
            let selectMenu = {};
            this.allMenuList.forEach(element => {
                element.childrenList.forEach(item => {
                    if (item.id == e) {
                        selectMenu = item;
                    }
                });
            });
            
            if (selectMenu.linkType == 3) {
                return this.navigateTo('detail', { id: selectMenu.articleId });
            } else if (selectMenu.linkType == 2) {
                return this.navigateTo('imglist', { id: selectMenu.id });
            } else {
                return this.navigateTo('list', { id: selectMenu.id });
            }
        },

        navigateTo(name, query = {}) {
            const targetRoute = { name: name, query: query };
            if (this.$route.name !== targetRoute.name || JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
                this.$router.push(targetRoute);
            }
        }
    }
}
</script>
<style lang="less" scoped>
.header {
    width: 100%;
    background: url(../assets/headerBg.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 20px 0;

    .logo {
        width: 20%;
        cursor: pointer;
    }
}

.inputBox {
    margin-left: 20px;
}

.openSide {
    margin-left: 10px;

    i {
        font-size: 30px;
        color: #fff;
    }
}

.mymenu {
    /deep/ .el-input__inner {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #333;
        border-radius: 0;
    }

    /deep/ .el-submenu__title * {
        vertical-align: baseline;
    }

    /deep/ input::-webkit-input-placeholder {
        color: #333;
    }

    /deep/ input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #333;
    }

    /deep/ input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #333;
    }

    /deep/ input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #333;
    }

    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
        margin-top: 0;
        margin-left: 4px;
    }

    /deep/ .el-input__icon {
        color: #000;
    }

    /deep/ .el-menu {
        background-color: transparent;
    }

    /deep/ .el-menu--horizontal>.el-menu-item.is-active,
    /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        color: #FC1527;
        border-bottom: 2px solid #FC1527;
    }

    /deep/ .el-submenu__title i {
        color: #000;
        font-size: 16px;

    }

    /deep/ .el-menu--horizontal>.el-menu-item,
    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title {
        color: #000;
        font-size: 16px;
        padding: 0 10px;
        background-color: transparent !important;
    }

    /deep/ .el-menu--horizontal>.el-menu-item:hover,

    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
        color: #FC1527 !important;
    }

    /deep/.el-menu.el-menu--horizontal {
        border: none;
    }
}


.sideMenu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 100vh;
    overflow: auto;
    z-index: 99999;
    transform: translateX(-200px);

    &.active {
        transform: translateX(0px);

    }

    .el-menu {
        height: 100%;
        border: none;
    }
}

@media screen and (max-width: 750px) {
    .header {
        width: 100%;
        padding: 10px 0;

        .logo {
            width: 30%;
        }
    }
}</style>