<template>
  <div id="app">
    <!-- <router-view /> -->
    <!--页面返回不刷新-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import {
  mapActions
} from "vuex";
export default {
  created() {
    this.GetMenu(0)
  },
  methods:{
    ...mapActions(['GetMenu']),

  }
}
</script>
<style lang="less">
.myDialog {
  width: 30%;
}

.mypopper {
  border: none !important;

  .el-menu {
    min-width: 140px !important;

    // 二级菜单中的子选项
    .el-menu-item {
      background-color: transparent !important;
      color: #000 !important;
      font-weight: 400;
      width: 100%;
      font-size: 16px;
      text-align: center;

      // 被选择的子选项
      &:not(.is-disabled):hover {
        color: #fff !important;
        background-color: #FC1527 !important;
        width: 100%;
        text-align: center;
      }
    }
  }

  // 弹出的二级菜单
  .el-menu--popup {
    min-width: 120px !important;
    border-top: #FC1527 2px solid;
  }

}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #fc1527;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #fc1527;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2
}

.h1,
h1 {
  font-size: 1.7rem
}

.h2,
h2 {
  font-size: 1.5rem
}

.h3,
h3 {
  font-size: 1.2rem
}

.h4,
h4 {
  font-size: 1.1rem
}

.h5,
h5 {
  font-size: 1rem
}

.h6,
h6 {
  font-size: 1rem
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.3s;
  word-break: break-all;
}

.line1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.commonBoxPadding {
  padding: 60px 0;
  overflow: hidden;
}

.line2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;

}

.line3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;

}

a {
  text-decoration: none;
  display: block;
}

.fontW {
  text-transform: uppercase;
}

.w {
  width: 1400px;
}

.maAuto {
  margin: 0 auto;
}

.flex {
  display: flex !important;
}

.fWrap {
  flex-wrap: wrap;
}

.fCol {
  flex-direction: column;
}

.acen {
  align-items: center;
}

.afirst {
  align-items: flex-start;
}

.aend {
  align-items: flex-end;
}

.jbet {
  justify-content: space-between;
}

.jcen {
  justify-content: center;
}

.jarod {
  justify-content: space-around;
}

.jend {
  justify-content: flex-end;
}

.acontent {
  align-content: space-between;
}

ul li {
  list-style: none;
}

.mb20 {
  margin-bottom: 20px;
}

.mr20 {
  margin-right: 20px;
}

.moblieHide {
  display: block;
}

.moblieShow {
  display: none;
}

@media screen and (max-width: 1400px) {
  .w {
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 750px) {
  .commonBoxPadding {
    padding: 20px 0;
  }

  .moblieHide {
    display: none !important;
  }

  .moblieShow {
    display: block !important;
  }

  .myDialog {
    width: 90%;
  }
}
</style>
