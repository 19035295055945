import Vue from 'vue'
import ElementUI from 'element-ui';
//引入echarts
import 'echarts'
//引入vue-echarts
import VueEcharts from 'vue-echarts'
import 'element-ui/lib/theme-chalk/display.css';
import 'element-ui/lib/theme-chalk/index.css';
import "quill/dist/quill.core.css"
import scroll from 'vue-seamless-scroll'
import App from './App.vue'
import router from './router'
import store from './store'
import header from './components/header.vue'
import commonTitle from './components/commonTitle.vue'
import footer from './components/footer.vue'
import side from './components/side.vue'
import wxyDrawer from './components/wxyDrawer.vue'
import proDrawer from './components/proDrawer.vue'


import countTo from 'vue-count-to'



//注册为全局组件
Vue.component('VueEcharts', VueEcharts)
Vue.config.productionTip = false
Vue.component('myheader', header)
Vue.component('commonTitle', commonTitle)
Vue.component('myfooter', footer)
Vue.component('side', side)
Vue.component('countTo', countTo)
Vue.component('wxyDrawer',wxyDrawer)
Vue.component('proDrawer',proDrawer)


Vue.use(scroll)
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
