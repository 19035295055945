import request from "@/utils/request";

// 文章列表
export function getArticlePage(data) {
  return request({
    url: "websiteApi/pub/getArticlePage",
    method: "post",
    data: data,
  });
}

// 文章详情
export function getArticleById(data) {
  return request({
    url: "websiteApi/pub/getArticleById",
    method: "post",
    data: data,
  });
}

// 捐赠列表
export function getDonationPage(data) {
  return request({
    url: "websiteApi/pub/getDonationPage",
    method: "post",
    data: data,
  });
}

// 支出列表
export function getExpendPage(data) {
  return request({
    url: "websiteApi/pub/getExpendPage",
    method: "post",
    data: data,
  });
}

// 菜单
export function getHomeCataloguesList(data) {
  return request({
    url: "websiteApi/pub/getHomeCataloguesList",
    method: "post",
    data: data,
  });
}

// banner
export function getBannerList(data) {
  return request({
    url: "websiteApi/img/page",
    method: "post",
    data: data,
  });
}

// 友情链接
export function getLinkList(data) {
  return request({
    url: "websiteApi/link/selectAll",
    method: "post",
    data: data,
  });
}

// 数据统计
export function getCharitableCount(data) {
  return request({
    url: "websiteApi/pub/getCharitableCount",
    method: "get",
    params: data,
  });
}
// 捐款动态

export function getDonationDynamicsPage(data) {
  return request({
    url: "websiteApi/pub/getDonationDynamicsPage",
    method: "post",
    data: data,
  });
}

// 小程序项目列表
export function findProjectListByTypePage(data) {
  return request({
    url: "websiteApi/pub/findProjectListByTypePage",
    method: "post",
    data: data,
  });
}

// 小程序微心愿列表
export function findWxyList(data) {
  return request({
    url: "websiteApi/pub/findWxyList",
    method: "post",
    data: data,
  });
}

// 项目详情
export function findProjectDesc(data) {
  return request({
    url: "websiteApi/pub/findProjectDesc",
    method: "post",
    data: data,
  });
}


// 微心愿详情
export function findWxyDesc(data) {
  return request({
    url: "websiteApi/pub/findWxyDesc",
    method: "post",
    data: data,
  });
}

// 项目海报
export function gyhaibao(data) {
  return request({
    url: "websiteApi/pub/gyhaibao",
    method: "get",
    params: data,
  });
}

// 微心愿海报
export function wxyhaibao(data) {
  return request({
    url: "websiteApi/pub/wxyhaibao",
    method: "get",
    params: data,
  });
}


//添加留言
export function addMsg(data) {
  return request({
    url: "websiteApi/feedback/insert",
    method: "post",
    data: data,
  });
}

// 获取城市
export function findTreeToSzList(data) {
  return request({
    url: 'clientApi/publicWelfareProjects/findTreeToSzList',
    method: 'post',
    data: data
  })
}

export function getMaxData() {
  return request({
    url: "websiteApi/pub/getMaxData",
    method: "get",
  });
}


// 查询对应的推荐项目数量/筹款总额/参与人数
export function findStoreCountOrAmountById(data) {
  return request({
    url: 'websiteApi/pub/findStoreCountOrAmount',
    method: 'post',
    data: data
  })
}


// 查询实时捐赠记录列表--分页
export function findProjectJuanKuanListByPage(data) {
  return request({
    url: 'websiteApi/pub/findProjectJuanKuanListByPage',
    method: 'post',
    data: data
  })
}


